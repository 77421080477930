import React, { useCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Remove from "@mui/icons-material/Delete";
import {
  Container,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  TextField,
  Stack,
} from "@mui/material";
import api from "../utils/api";

export default function UsersList({ users, refresh }) {
  const [usersList, setUsers] = useState(users);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    if (!query) return setUsers(users);
    setUsers(users.filter(({ email }) => email.toLowerCase().includes(query)));
  }, [query, users]);

  const remove = useCallback(
    async (id) => {
      if (!window.confirm(`Are you sure you want to delete user?`)) return;

      try {
        await api.deleteUser(id);
        await refresh();
        window.alert(`User removed!`);
      } catch (err) {
        window.alert(`Error deleting user: ${err.message}`);
      }
    },
    [refresh]
  );

  return (
    <Container sx={{ py: 2 }} maxWidth="lg">
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h5" color="text.secondary">
          Users
        </Typography>
        <TextField
          size="small"
          placeholder="Search users"
          sx={{ p: 1 }}
          onChange={({ target }) => setQuery(target.value)}
        />
      </Stack>
      <Grid container spacing={4}>
        {usersList.map((card) => (
          <Grid item key={card._id || card.id} xs={3}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <CardMedia
                component="img"
                sx={{ height: "200px" }}
                image={card.avatar}
                alt="avatar"
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant="h5" component="h4">
                  Email: {card.email}
                </Typography>
                <Typography>Name: {card.name}</Typography>
              </CardContent>
              <CardActions>
                <IconButton color="error" onClick={() => remove(card._id)}>
                  <Remove color="inherit" />
                </IconButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
