import Remove from "@mui/icons-material/Delete";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const RestaurantCard = ({ card, remove, updateFee }) => {
  const [stripeFee, setStripeFee] = useState(null);

  useEffect(() => {
    setStripeFee(card.commissionStripeFee || null);
  }, [card.commissionStripeFee]);

  const updateFeeValue = () => {
    if (stripeFee < 0 || stripeFee > 100) {
      window.alert("Commission fee must be between 0 and 100");
      return;
    }
    updateFee(card._id, stripeFee);
  };

  return (
    <Grid item key={card._id || card.id} xs={3}>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardMedia
          component="img"
          image={card.log || card.image}
          sx={{ height: "200px" }}
          alt="avatar"
        />
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="h5" component="h5">
            {card.name}
          </Typography>
        </CardContent>
        <CardActions display="flex" justifyContent="flex-end">
          <TextField
            type="number"
            value={stripeFee}
            label="Commission %"
            variant="outlined"
            InputProps={{
              inputProps: {
                min: 0, // Sets minimum value
                max: 100, // Sets maximum value
              },
            }}
            fullWidth
            sx={{ margin: 1 }} // Adjust spacing as needed
            size="small"
            onChange={({ target }) => setStripeFee(target.value)}
            onBlur={updateFeeValue}
          />
          <IconButton color="error" onClick={() => remove(card._id)}>
            <Remove color="inherit" />
          </IconButton>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default RestaurantCard;
