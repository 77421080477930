import React, { useCallback, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import Modal from "@mui/material/Modal";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList/index";
import TabPanel from "@mui/lab/TabPanel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import api from "../../utils/api";
import Copyright from "../../components/Copyright";
import NewRestaurantLinkForm from "../../components/forms/NewRestaurantLink";
import UsersList from "../../components/UsersList";
import RestaurantList from "../../components/RestaurantList";

const theme = createTheme();

export default function Users({ onLogout }) {
  const [isFormVisible, setIsVisible] = useState(false);
  const [users, setUsers] = useState([]);
  const [restaurants, setRestaurants] = useState([]);
  const [curTab, setTab] = useState("1");

  const handleTabChange = (_, newValue) => {
    setTab(newValue);
  };

  const getUsers = useCallback(() => {
    api
      .getUsers()
      .then((res) => {
        console.log(res);
        setUsers(res?.list || res);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getRestaurants = useCallback(() => {
    api.getRestaurants().then((res) => {
      setRestaurants(res?.list || res);
    });
  }, []);

  const updateRestaurantCommissionFee = useCallback(
    (id, commissionStripeFee) => {
      api
        .updateRestaurantCommissionFee(id, { commissionStripeFee })
        .then(() => {
          getRestaurants();
        })
        .catch((err) => {
          console.error(err);
        });
    },
    [getRestaurants]
  );

  useEffect(() => {
    getUsers();
    getRestaurants();
  }, [getUsers, getRestaurants]);

  const openForm = useCallback(() => {
    setIsVisible(true);
  }, []);

  const handleLogout = useCallback(() => {
    api.logout();
    onLogout();
  }, [onLogout]);

  const createRestaurantLink = useCallback(
    async ({ email, name }) => {
      try {
        await api.createRestaurantLink({ email, name });
        await getRestaurants();
        setIsVisible(false);
      } catch (err) {
        console.error(err);
        alert(`Error: ${err?.message || err?.statusText}`);
      }
    },
    [getRestaurants]
  );

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={isFormVisible}
        onClose={() => setIsVisible(false)}
        xs={{
          mt: 20,
        }}
      >
        <NewRestaurantLinkForm onSubmit={createRestaurantLink} />
      </Modal>
      <CssBaseline />
      <TabContext value={curTab}>
        <AppBar position="sticky">
          <Toolbar>
            <Stack direction="row" spacing={2} justifyContent="space-between" flex={1}>
              <Stack direction="row" alignItems={"center"} spacing={2} justifyContent="center">
                <LogoutIcon className="hover-pointer" onClick={handleLogout} alt="Logout" />
                <Typography variant="h6" color="inherit" noWrap>
                  Dashboard
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2} justifyContent="center">
                <TabList
                  onChange={handleTabChange}
                  textColor="info"
                  indicatorColor="secondary"
                  aria-label="lab API tabs example"
                >
                  <Tab label="Restaurants" value="1" />
                  <Tab label="Users" value="2" />
                </TabList>
              </Stack>

              <Stack sx={{ pl: 2 }} direction="row" spacing={2} justifyContent="center">
                <Button variant="contained" color="info" onClick={openForm}>
                  Add new Restaurant
                </Button>
                <Button variant="outlined" color="warning" onClick={getUsers}>
                  Refresh List
                </Button>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>

        <TabPanel value="1">
          <RestaurantList
            restaurants={restaurants}
            refresh={getRestaurants}
            updateRestaurantCommissionFee={updateRestaurantCommissionFee}
          />
        </TabPanel>
        <TabPanel value="2">
          <UsersList users={users} refresh={getUsers} />
        </TabPanel>
      </TabContext>

      <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Copyright />
      </Box>
    </ThemeProvider>
  );
}
