import React, { useCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Grid, Container, Stack, TextField } from "@mui/material";
import api from "../utils/api";
import RestaurantCard from "./RestaurantCard";

export default function RestaurantList({ restaurants, refresh, updateRestaurantCommissionFee }) {
  const [restaurantsList, setRestaurants] = useState(restaurants);
  const [query, setQuery] = useState(null);

  useEffect(() => {
    if (!query) return setRestaurants(restaurants);
    setRestaurants(restaurants.filter(({ name }) => name.toLowerCase().includes(query)));
  }, [query, restaurants]);

  const remove = useCallback(
    async (id) => {
      if (!window.confirm(`Are you sure you want to delete restaurant?`)) {
        return;
      }
      try {
        await api.deleteRestaurant(id);
        await refresh();
        window.alert(`Restaurant is removed`);
      } catch (err) {
        window.alert(`Error deleting restaurant: ${err.message}`);
      }
    },
    [refresh]
  );

  const updateFee = useCallback(
    async (id, commissionStripeFee) => {
      try {
        if (commissionStripeFee < 0 || commissionStripeFee > 100)
          return window.alert("Commission fee must be between 0 and 100");
        await updateRestaurantCommissionFee(id, commissionStripeFee);
        await refresh();
      } catch (err) {
        window.alert(`Error updating commission fee: ${err.message}`);
      }
    },
    [refresh, updateRestaurantCommissionFee]
  );

  return (
    <Container sx={{ py: 2 }} maxWidth="lg">
      <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
        <Typography variant="h5" color="text.secondary">
          Restaurants
        </Typography>
        <TextField
          size="small"
          placeholder="Search restaurants"
          sx={{ p: 1 }}
          onChange={({ target }) => setQuery(target.value)}
        />
      </Stack>
      <Grid container spacing={4}>
        {restaurantsList?.map((card) => (
          <RestaurantCard key={card._id} card={card} updateFee={updateFee} remove={remove} />
        ))}
      </Grid>
    </Container>
  );
}
