import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export default function NewRestaurantLinkForm({ onSubmit }) {
  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    onSubmit({
      email: data.get('email'),
      name: data.get('name'),
    });
  },[onSubmit]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        backgroundColor: "white",
        maxWidth: "60vw",
        padding: "20px",
        margin: "auto",
        borderRadius: 5,
        mt: 20,
      }}
    >
      <Typography variant="h5" align="center">
        Invite new Restaurant
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        name="name"
        label="Restaurant Name"
        type="name"
        id="name"
        autoComplete="current-name"
        autoFocus
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        Invite
      </Button>
    </Box>
  );
}
