import "./App.css";
import LoginForm from "./pages/login/Login";
import { useCallback, useEffect, useState } from "react";
import Dashboard from './pages/dashboard/Dashboard';
import api from "./utils/api";
import config from "./constants";

function App() {
  const [isLogged, setIsLogged] = useState(false);
  useEffect(() => {
    const tok = localStorage.getItem(config.tKey);
    setIsLogged(!!tok);
  }, []);

  const handleLogin = useCallback(async ({ email, password }) => {
    try {
      const res = await api.logIn({ email, password });
      localStorage.setItem(config.tKey, res.token);
      setIsLogged(true);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const onLogout = useCallback(() => {
    setIsLogged(false);
  }, []);

  return (
    <div className="App">
      {isLogged ? (
        <Dashboard onLogout={onLogout} />
      ) : (
        <LoginForm onSubmit={handleLogin} />
      )}
    </div>
  );
}

export default App;
