import conf from "../constants";
const nToken = () => localStorage.getItem(conf.tKey) || "";

const api = {
  get: (url, token = true) =>
    fetch(`${conf.host}${url}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${nToken()}` }),
      },
    }).then((res) => {
      if (!res.ok) throw res;
      return res.json();
    }),
  post: (url, body, token = true) =>
    fetch(`${conf.host}${url}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${nToken()}` }),
      },
      body: JSON.stringify(body),
    }).then((res) => {
      if (!res.ok) throw res;
      return res.json();
    }),
  put: (url, body, token = true) =>
    fetch(`${conf.host}${url}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${nToken()}` }),
      },
      body: JSON.stringify(body),
    }).then((res) => {
      if (!res.ok) throw res;
      return res.json();
    }),
  delete: (url, token = true) =>
    fetch(`${conf.host}${url}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${nToken()}` }),
      },
    }).then((res) => {
      if (!res.ok) throw res;
      return res.json();
    }),
};

// eslint-disable-next-line  import/no-anonymous-default-export
export default {
  logIn: ({ email, password }) => api.post("/web/auth", { email, password }),
  getUsers: () => api.get("/api/users", true),
  getRestaurants: () => api.get("/api/restaurants", true),
  logout: () => {
    localStorage.removeItem(conf.tKey);
  },
  deleteUser: (id) => api.delete(`/api/users/${id}`),
  deleteRestaurant: (id) => api.delete(`/web/restaurants/${id}`),
  createRestaurantLink: (body) => api.post("/web/restaurants", body),
  updateRestaurantCommissionFee: (restaurantId, body) =>
    api.put(`/api/restaurants/${restaurantId}/stripe/fee`, body, true),
};
